import React, { useState, useEffect } from 'react';
import {useGame} from './gameContext.jsx';

function MetaDataTable() {
    const [meta, setMeta] = useState({});
    const [player, setPlayer] = useState({});
    const [pergame, setPerGame] = useState([]);
    // const [show, setShow] = useState({
    //     // position: false,
    //     // height: false,
    //     // weight: false,
    //     // school: false,
    //     // draft: false,
    //     // awards: false
    //     position: localStorage.getItem('showPosition') === 'true',
    //     height: localStorage.getItem('showHeight') === 'true',
    //     weight: localStorage.getItem('showWeight') === 'true',
    //     school: localStorage.getItem('showSchool') === 'true',
    //     draft: localStorage.getItem('showDraft') === 'true',
    //     awards: localStorage.getItem('showAwards') === 'true'
    // });
    const {
        setPointsRemaining,
        setPosition,
        setHeight,
        setWeight,
        setSchool,
        setDraft,
        setAwards,
        setHintsUsed,
        showPosition,
        showHeight,
        showWeight,
        showSchool,
        showDraft,
        showAwards
    } = useGame();

    useEffect(() => {
        //Fetch data from current/pergame
        fetch(`${process.env.REACT_APP_API_BASE_URL}/current/meta`)
            .then(response => response.json())
            .then(data => setMeta(data[0] || {}))
            .catch(error => console.error('Error:', error));
        fetch(`${process.env.REACT_APP_API_BASE_URL}/current/player`)
            .then(response => response.json())
            .then(data => setPlayer(data[0] || {}))
            .catch(error => console.error('Error:', error));
        fetch(`${process.env.REACT_APP_API_BASE_URL}/current/pergame`)
            .then(response => response.json())
            .then(data => setPerGame(data))
            .catch(error => console.error('Error:', error));
    }, []); // The empty array ensures this effect runs once on mount

    const toggleShow = ({key, points, localStorageKey}) => {

        setPointsRemaining(prev => prev - (points || 0));

        const toggleMap = {
            showPosition: setPosition,
            showHeight: setHeight,
            showWeight: setWeight,
            showSchool: setSchool,
            showDraft: setDraft,
            showAwards: setAwards
        };

        const setState = toggleMap[localStorageKey];

        if (setState) {
            setState(true);
            setHintsUsed(prevHints => [...prevHints, key]);
        } else {
            console.warn('Unknown key for toggleShow:', localStorageKey)
        }
        // setShow(prevShow => ({
        //     ...prevShow,
        //     [key]: !prevShow[key]
        // }));
    };

    const processAwards = () => {
        const nonNullAwards = pergame
            .filter(row => row.Awards !== null && row.Awards !== "" && !row.Awards.includes("DNP"))
            .map(row => `${row.Awards} (${row.Season})`)
        return nonNullAwards.join(", ");
    };

    const awardsDisplay = processAwards();
    console.log('Position', showPosition);
    console.log('Height', showHeight);
    console.log('School', showSchool);

    return(
        <div className = 'PlayerInfo'>
            <div className="player-hint">
                <span className="player-key">Position(s): </span>
                {!showPosition && (
                    <button onClick={() => toggleShow({key:'position', points: 20, localStorageKey: 'showPosition'})} className="spoiler-button">
                        REVEAL (-20 PTS)
                    </button>
                )}
                {showPosition && (
                    <span className="player-value">{meta.position}</span>
                )}
            </div>
            <div className="player-hint">
                <span className="player-key">Height: </span>
                {!showHeight && (
                    <button onClick={() => toggleShow({key:'height', points: 20, localStorageKey: 'showHeight'})} className="spoiler-button">
                        REVEAL (-20 PTS)
                    </button>
                )}
                {showHeight && (
                    <span className="player-value">{player.Ht}</span>
                )}
            </div>
            <div className="player-hint">
                <span className="player-key">Weight: </span>
                {!showWeight && (
                    <button onClick={() => toggleShow({key:'weight', points: 20, localStorageKey: 'showWeight'})} className="spoiler-button">
                        REVEAL (-20 PTS)
                    </button>
                )}
                {showWeight && (
                    <span className="player-value">{player.Wt}</span>
                )}
            </div>
            <div className="player-hint">
                <span className="player-key">School: </span>
                {!showSchool && (
                    <button onClick={() => toggleShow({key:'school', points: 20, localStorageKey: 'showSchool'})} className="spoiler-button">
                        REVEAL (-20 PTS)
                    </button>
                )}
                {showSchool && (
                    <span className="player-value">{meta.school}</span>
                )}
            </div>
            <div className="player-hint">
                <span className="player-key">Draft: </span>
                {!showDraft && (
                    <button onClick={() => toggleShow({key:'draft', points: 20, localStorageKey: 'showDraft'})} className="spoiler-button">
                        REVEAL (-20 PTS)
                    </button>
                )}
                {showDraft && (
                    <span className="player-value">{meta.draft}</span>
                )}
            </div>
            {awardsDisplay && 
            <div className="player-hint">
                <span className="player-key">Awards: </span>
                {!showAwards && (
                    <button onClick={() => toggleShow({key:'awards', points: 20, localStorageKey: 'showAwards'})} className="spoiler-button">
                        REVEAL (-20 PTS)
                    </button>
                )}
                {showAwards && (
                    <span className="player-value">{awardsDisplay}</span>
                )}
            </div>
            }
        </div>
    );
}

export default MetaDataTable;