import React, { useState, useEffect } from 'react';
import {useGame} from './gameContext.jsx';

function TeamInfo() {
    const [data, setData] = useState([]);
    // const [show, setShow] = useState({
    //     team: localStorage.getItem('showTeam') === 'true'
    // });
    const {setPointsRemaining, setTeam, showTeam, setHintsUsed} = useGame();


    useEffect(() => {
        //Fetch data from current/team
        fetch(`${process.env.REACT_APP_API_BASE_URL}/current/team`)
            .then(response => response.json())
            .then(data => setData(data))
            .catch(error => console.error('Error:', error));
    }, []); // The empty array ensures this effect runs once on mount

    const toggleCover = ({columnName, points}) => {
        // setShow(prevShow => ({
        //     ...prevShow,
        //     [columnName]: !prevShow[columnName]
        // }));

        setTeam(true);
        setPointsRemaining(prev => prev - (points || 0));
        setHintsUsed(prevHints => [...prevHints, columnName]);
    };

    return(
        <div className="team-history-container">
            {!showTeam && (
                <button onClick={() => toggleCover({columnName: 'team', points: 80})} className="spoiler-button" style={{height:'150px', minWidth:'250px'}}>
                    REVEAL (-80 PTS)
                </button>
            )}
            {showTeam && (
                data.map((row,index) => (
                    <React.Fragment key={index}>
                        {index > 0 && <img src="/arrow.png" alt="arrow pointing right" className="team-arrow" />}
                        <div className="team-contaner">
                            <img src={row.logo_src} alt={row.logo_alt} className="team-logo" />
                            <div className="team-season">{row.Season}</div>
                        </div>
                    </React.Fragment>
                ))
            )}
        </div>
    );
};

export default TeamInfo;