import React, {useState, useEffect, useRef} from 'react';
import Swal from 'sweetalert2';
import {useGame} from './gameContext.jsx';

const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: false,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter',Swal.stopTimer)
      toast.addEventListener('mouseleave',Swal.resumeTimer)
    }
  });

function PlayerSearch() {
    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [suggestionSelected, setSuggestionSelected] = useState(false);
    const [isSelecting, setIsSelecting] = useState(false);
    const tooltipRef = useRef(null);
    const [guesses, setGuesses] = useState([]);
    // const [guessesRemaining, setGuessesRemaining] = useState(5);
    const {guessesRemaining, 
        setGuessesRemaining, 
        pointsRemaining, 
        setPointsRemaining, 
        isGameOver, 
        setIsGameOver, 
        endGameState,
        hintsUsed,
        rundate} = useGame();
    const inputRef = useRef(null);
    const [headshot, setHeadshot] = useState({});

    // console.log("Is the game over?",isGameOver);

    useEffect(() => {
        //Fetch data from current/pergame
        fetch(`${process.env.REACT_APP_API_BASE_URL}/current/meta`)
            .then(response => response.json())
            .then(data => setHeadshot(data[0] || {}))
            .catch(error => console.error('Error:', error));
    }, []); // The empty array ensures this effect runs once on mount

    function generateGuessesEmoji(guessesRemaining, isCorrect) {
        const maxGuesses = 5;        
        const guessesTaken = maxGuesses - guessesRemaining;
        const redSquare = '🟥';
        const greenSquare = '🟩';
        let emojiString = '';

        const redSquaresCount = isCorrect ? guessesTaken - 1 : guessesTaken;
        const greenSquaresCount = isCorrect ? 1 : 0;

        emojiString += redSquare.repeat(redSquaresCount);
        emojiString += greenSquare.repeat(greenSquaresCount);

        return emojiString;
    }

    const handleSubmit = () => {
        const guess = query.trim();
        if (!guess) return; // Early return if guess is empty

        // console.log("Guess:", guess);

        if (guesses.includes(guess)) {
            Toast.fire({
                title: "Already guessed."
            });
            setQuery('');
            return;
        }

        checkWin(guess).then(win => {
            // console.log("Check game status", isGameOver);
            // console.log("Guess:", guess);
            // console.log("Check win status", win);
            // console.log("Guesses remaining:", guessesRemaining)

            const hintToEmoji = {
                team: '🎽',
                position: '⛹️‍♂️',
                height: '📏',
                weight: '⚖️',
                school: '🎓',
                draft: '🌟',
                awards: '🏆'
            };

            const hintsArray = Array.isArray(hintsUsed) ? hintsUsed : [];

            const hintsString = hintsArray.length === 0
                ? 'None'
                : hintsArray.map(hint => hintToEmoji[hint] || hint).join(' ');

            const textHeader = `NBAGuessr ${rundate}`;

            const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

            if (win) {
                const updatedGuessesRemaining = guessesRemaining - 1;
                const guessResult = generateGuessesEmoji(updatedGuessesRemaining, true);
                const guessesTaken = 5 - updatedGuessesRemaining;

                setGuessesRemaining(updatedGuessesRemaining);
                setIsGameOver(true);
                endGameState();
                setGuesses(prevGuesses => [...prevGuesses, guess]);
                Swal.fire({
                    title: "Congratulations!",
                    html: `
                    <p><b>Today's player: <a href="${headshot.player_page}" target="_blank">${headshot.Player}</a></b></p>
                    <div class="alert-container">
                        <div class="alert-item">
                            <div class="alert-title">Guesses</div>
                            <div class="alert-value">${guessesTaken}</div>
                        </div>
                        <div class="alert-item">
                            <div class="alert-title">Points</div>
                            <div class="alert-value">${pointsRemaining}</div>
                        </div>
                    </div>
                    <div>
                        <div class = "alert-item">
                            <div class="alert-title">Hints Used</div>
                            <div class = "alert-value">${hintsString}</div>
                        </div>
                    </div>
                    `,
                    // text: "You win!",
                    imageUrl: headshot.headshot_link,
                    imageAlt: headshot.headshot_alt,
                    showCancelButton: true,
                    confirmButtonText: 'See Results',
                    cancelButtonText: 'Share',
                    preConfirm: () => {
                        console.log('Confirmed');
                    }
                }).then((result) => {
                    if (result.dismiss === Swal.DismissReason.cancel) {
                        console.log('Share button clicked');

                        const textToDisplay = `${textHeader} \n ${guessResult} \n\n Points: ${pointsRemaining} \n Hints Used: \n ${hintsString} \n\n www.nbaguessr.com`

                        if (navigator.share && isMobileDevice) {
                            navigator.share({
                                title: `${textHeader}`,
                                text: `${guessResult} \n\n Hints Used: ${hintsString} \n\n www.nbaguessr.com`,
                                url: window.location.href
                            }).catch(error => {
                                console.error('Error sharing:', error);
                            });
                        } else if (navigator.clipboard) {
                            navigator.clipboard.writeText(textToDisplay)
                                .then(() => {
                                    Toast.fire({
                                        title: 'Copied results to clipboard!'
                                    });
                                })
                                .catch(err => {
                                    console.error('Failed to copy text to clipboard', err);
                                });
                        }
                    }
                });
            } else {
                if (guessesRemaining > 1) {
                    Toast.fire({
                        title: "Incorrect guess. Try again."
                    });
                    setGuesses(prevGuesses => [...prevGuesses, guess]);
                    setQuery('');
                    setGuessesRemaining(prev => prev - 1);
                    setPointsRemaining(prev => prev - 20);
                } else {
                    const updatedGuessesRemaining = guessesRemaining - 1;
                    const guessResult = generateGuessesEmoji(updatedGuessesRemaining, false);
                    const guessesTaken = 5 - updatedGuessesRemaining;
    
                    setGuessesRemaining(updatedGuessesRemaining);
                    setPointsRemaining(0);
                    setIsGameOver(true);
                    endGameState();

                    Swal.fire({
                        title: "Out of guesses!",
                        html: `
                        <p> Come back again tomorrow</p>
                        <p>The player was: <a href="${headshot.player_page}" target="_blank">${headshot.Player}</a></p>
                        `,
                        // text: "Come back tomorrow",
                        imageUrl: headshot.headshot_link,
                        imageAlt: headshot.headshot_alt,
                        showCancelButton: true,
                        confirmButtonText: 'See Results',
                        cancelButtonText: 'Share',
                        preConfirm: () => {
                            console.log('Confirmed');
                        }
                    }).then((result) => {
                        if (result.dismiss === Swal.DismissReason.cancel) {
                            console.log('Share button clicked');
    
                            const textToDisplay = `${textHeader} \n ${guessResult} \n\n Points: ${pointsRemaining} \n Hints Used: \n ${hintsString} \n\n www.nbaguessr.com`
    
                            if (navigator.share && isMobileDevice) {
                                navigator.share({
                                    title: 'NBAGuessr',
                                    text: textToDisplay,
                                    url: window.location.href
                                }).catch(error => {
                                    console.error('Error sharing:', error);
                                });
                            } else if (navigator.clipboard) {
                                navigator.clipboard.writeText(textToDisplay)
                                    .then(() => {
                                        Toast.fire({
                                            title: 'Copied results to clipboard!'
                                        });
                                    })
                                    .catch(err => {
                                        console.error('Failed to copy text to clipboard', err);
                                    });
                            }
                        }
                    });
                }
            }
        }).catch(error => {
            console.error("Error checking win status:", error);
        });
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            handleSubmit();
        } else if ((event.key === "Backspace" || event.key === "Delete") && suggestionSelected) {
            event.preventDefault();
            setQuery('');
            setSuggestionSelected(false);
        }
    };

    const checkWin = async (guess) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/current/player`);
            const data = await response.json();

            const correctAnswer = data[0].Player.toLowerCase();

            // console.log("Guess:", guess);
            // console.log("Correct:", data[0].Player);

            return guess.toLowerCase() === correctAnswer;
        } catch (error) {
            console.error("Error fetching correct answer:", error);
            throw error;
        }
    };

    useEffect(() => {
        if (query !== '' && !isSelecting) {
            fetch(`${process.env.REACT_APP_API_BASE_URL}/players/${encodeURIComponent(query)}`)
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setSuggestions(data);
                    setShowSuggestions(true);
                })
                .catch(error => console.error('Error fetching suggestions:', error));
        } else {
            setSuggestions([]);
            setShowSuggestions(false);
            // if (isSelecting) {
            //     setIsSelecting(false);
            // }
        }
    }, [query, isSelecting]);

    // Handles input changes
    const handleInputChange = (event) => {
        setQuery(event.target.value);
        setSuggestionSelected(false);
        if (isSelecting) setIsSelecting(false);
    };

    //Function to select a player from suggestions
    const selectPlayer = (selectedName) => {
        setIsSelecting(true);
        setSuggestionSelected(true);
        setQuery(selectedName);
        setShowSuggestions(false);

        if (inputRef.current) {
            inputRef.current.focus();
            inputRef.current.setSelectionRange(0,0);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
                setShowSuggestions(false);
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <div>
            {isGameOver ? (
                <div className="player-details">
                    <img src={headshot.headshot_link} alt={headshot.headshot_alt} className="player-image" />
                    <div className="player-name">{headshot.Player}</div>
                </div>
            ) : (
            <div className = "player-search-container">
                <div className="input-container">
                    <input
                        ref={inputRef}
                        type="text"
                        id="playerSearch"
                        placeholder="Guess a player..."
                        value={query}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        className="player-input"
                    />
                    <button className="submit-button" onClick={handleSubmit}>Submit</button>
                </div>
                {showSuggestions && (
                    <div id="suggestions" ref={tooltipRef} className="tooltip" style={{display: showSuggestions ? 'block' : 'none'}}>
                        {suggestions.length > 0 ? (suggestions.map((suggestion,index) => (
                            <div onClick={() => selectPlayer(suggestion.Player)}>
                                {suggestion.Player}
                            </div>
                        ))
                        ) : (
                            <div>No matches found</div>
                        )}
                    </div>
                )}
            </div>
            )}
        </div>
    );
}

export default PlayerSearch;