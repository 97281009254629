import React, { useState, useEffect } from 'react';
// import {useGame} from './gameContext.jsx';

// function formatAsPercentage(number) {
//     return `${(number * 100).toFixed(1)}%`;
// };

function PerGameTable() {
    const [data, setData] = useState([]);
    // const [show, setShow] = useState({
    //     season: false,
    //     team: false,
    //     awards: false
    // });
    // const {setPointsRemaining} = useGame();
    // const [activeTab, setActiveTab] = useState('Stats');

    // const handleTabClick = (tabName) => {
    //   setActiveTab(tabName);
    // };

    useEffect(() => {
        //Fetch data from current/pergame
        fetch(`${process.env.REACT_APP_API_BASE_URL}/current/pergame`)
            .then(response => response.json())
            .then(data => setData(data))
            .catch(error => console.error('Error:', error));
    }, []); // The empty array ensures this effect runs once on mount

    // const toggleCover = ({columnName, points}) => {
    //     setShow(prev => ({
    //         ...prev,
    //         [columnName]: !prev[columnName]
    //     }));
    //     setPointsRemaining(prev => prev - (points || 0));
    // };

    return(
        <div>
            <div className="PerGameStats">
                <div className = "pg-column">
                    <div className="pg-header">
                        <div className="grid-header">Season</div>
                        {data.map((row) => (
                            <div className="cell">{row.Season}</div>
                        ))}
                    </div>
                </div>
                {/* <div className = "pg-column">
                    <div className="pg-header">
                        <div className="grid-header">G</div>
                        {data.map((row) => (
                            <div className="cell">{row.G}</div>
                        ))}
                    </div>
                </div>
                <div className = "pg-column">
                    <div className="pg-header">
                        <div className="grid-header">GS</div>
                        {data.map((row) => (
                            <div className="cell">{row.GS}</div>
                        ))}
                    </div>
                </div> */}
                <div className = "pg-column">
                    <div className="pg-header">
                        <div className="grid-header">3PM</div>
                        {data.map((row) => (
                            <div className="cell">{row.x3P}</div>
                        ))}
                    </div>
                </div>
                <div className = "pg-column">
                    <div className="pg-header">
                        <div className="grid-header">PTS</div>
                        {data.map((row) => (
                            <div className="cell">{row.PTS}</div>
                        ))}
                    </div>
                </div>
                <div className = "pg-column">
                    <div className="pg-header">
                        <div className="grid-header">AST</div>
                        {data.map((row) => (
                            <div className="cell">{row.AST}</div>
                        ))}
                    </div>
                </div>
                <div className = "pg-column">
                    <div className="pg-header">
                        <div className="grid-header">REB</div>
                        {data.map((row) => (
                            <div className="cell">{row.TRB}</div>
                        ))}
                    </div>
                </div>
                <div className = "pg-column">
                    <div className="pg-header">
                        <div className="grid-header">STL</div>
                        {data.map((row) => (
                            <div className="cell">{row.STL}</div>
                        ))}
                    </div>
                </div>
                <div className = "pg-column">
                    <div className="pg-header">
                        <div className="grid-header">BLK</div>
                        {data.map((row) => (
                            <div className="cell">{row.BLK}</div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PerGameTable;