import React from 'react';
import {useGame} from './gameContext.jsx';

function GuessesDisplay() {
    const {guessesRemaining, pointsRemaining} = useGame();

    return (
        <div className = "status-container">
            <div className = "status-item">
                <div className = "status-title">GUESSES REMAINING</div>
                <div className = "status-value">{guessesRemaining}</div>
            </div>
            <div className = "status-item">
                <div className = "status-title">POINTS REMAINING</div>
                <div className = "status-value">{pointsRemaining}</div>
            </div>
        </div>
    )
}

export default GuessesDisplay;