import React, {createContext, useContext, useState, useEffect} from 'react';

const GameContext = createContext();

export const useGame = () => useContext(GameContext);

export const GameProvider = ({ children }) => {
    const [guessesRemaining, setGuessesRemaining] = useState(() =>
        parseInt(localStorage.getItem('guessesRemaining')));

    // console.log(guessesRemaining);
    
    const [pointsRemaining, setPointsRemaining] = useState(() =>
        parseInt(localStorage.getItem('pointsRemaining')));

    // console.log(pointsRemaining);

    const [showPosition, setPosition] = useState(() => localStorage.getItem('showPosition') === 'true');
    const [showTeam, setTeam] = useState(() => localStorage.getItem('showTeam') === 'true');
    const [showHeight, setHeight] = useState(() => localStorage.getItem('showHeight') === 'true');
    const [showWeight, setWeight] = useState(() => localStorage.getItem('showWeight') === 'true');
    const [showSchool, setSchool] = useState(() => localStorage.getItem('showSchool') === 'true');
    const [showDraft, setDraft] = useState(() => localStorage.getItem('showDraft') === 'true');
    const [showAwards, setAwards] = useState(() => localStorage.getItem('showAwards') === 'true');
    const [isGameOver, setIsGameOver] = useState(() => localStorage.getItem('GameStatus') === 'true');
    const [hintsUsed, setHintsUsed] = useState(() => {
        const storedHints = localStorage.getItem('HintsUsed');
        return storedHints ? JSON.parse(storedHints) : [];
    });
    const [rundate, setRundate] = useState();

    console.log('Game Over?', isGameOver);

    useEffect(() => {
        const fetchData = async() => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/current/player`);
                const data = await response.json();
                console.log(data);
    
                const current_pid = data[0].PlayerID;
                const prev_pid = localStorage.getItem('PlayerID');

                console.log('CurrentPID:', current_pid);
                console.log('Stored PID:', prev_pid);
    
                if (parseInt(current_pid) !== parseInt(prev_pid)) {
                    console.log('Updating values');
                    localStorage.setItem('PlayerID', current_pid);
                    setGuessesRemaining(5);
                    setPointsRemaining(400);
                    setPosition(false);
                    setTeam(false);
                    setHeight(false);
                    setWeight(false);
                    setSchool(false);
                    setDraft(false);
                    setAwards(false);
                    setIsGameOver(false);
                    setHintsUsed([]);
                } else {
                    console.log('Nothing to do');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, []);

    function formatDate(dateString) {
        const date = new Date(dateString);
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();

        return `${month}/${day}/${year}`;
    }

    useEffect(() => {
        // Fetch the current run date from the API
        const fetchDate = async() => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/meta/current_rundate`);
                const data = await response.json();
                const date = data && data.length > 0 ? data[0].date : null;
                const formattedDate = formatDate(date);

                setRundate(formattedDate);

            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchDate();
    }, []);

    useEffect(() => {
        localStorage.setItem('guessesRemaining', guessesRemaining);
        localStorage.setItem('pointsRemaining', pointsRemaining);
        localStorage.setItem('pointsRemaining', pointsRemaining);
        localStorage.setItem('showPosition', showPosition);
        localStorage.setItem('showTeam', showTeam);
        localStorage.setItem('showHeight', showHeight);
        localStorage.setItem('showWeight', showWeight);
        localStorage.setItem('showSchool', showSchool);
        localStorage.setItem('showDraft', showDraft);
        localStorage.setItem('showAwards', showAwards);
        localStorage.setItem('GameStatus', isGameOver);
        localStorage.setItem('HintsUsed', JSON.stringify(hintsUsed));
    }, [guessesRemaining, 
        pointsRemaining, 
        showPosition,
        showTeam, 
        showHeight, 
        showWeight, 
        showSchool, 
        showDraft, 
        showAwards, 
        isGameOver,
        hintsUsed
    ]);

    function endGameState() {
        setPosition(true);
        setTeam(true);
        setHeight(true);
        setWeight(true);
        setSchool(true);
        setDraft(true);
        setAwards(true);
    };

    const value = {
        guessesRemaining, setGuessesRemaining,
        pointsRemaining, setPointsRemaining,
        showPosition, setPosition,
        showTeam, setTeam,
        showHeight, setHeight,
        showWeight, setWeight,
        showSchool, setSchool,
        showDraft, setDraft,
        showAwards, setAwards,
        isGameOver, setIsGameOver,
        endGameState,
        hintsUsed, setHintsUsed,
        rundate, setRundate
    };
    
    return (
        <GameContext.Provider value={value}>
            {children}
        </GameContext.Provider>
    );
};