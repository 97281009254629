import React from 'react';
import './App.css';

// import {CookieProvider} from './components/cookieContext.jsx';
import PerGameTable from './components/PerGameTable.jsx';
import TeamInfo from './components/TeamInfo.jsx';
import MetaDataTable from './components/MetaData.jsx';
import PlayerSearch from './components/playerSearch.jsx';
import GuessesDisplay from './components/guessManager.jsx';
import {GameProvider} from './components/gameContext.jsx';
// import Game from './components/guessManager.jsx';



function App() {
  return (
    // <CookieProvider>
      <GameProvider>
        <div className="App">
          <header className="App-header">
            {/* <h1>NBAGuessr</h1> */}
            <img className="nba-guessr-logo" src = "/NBAGuessr_Logo-White.png" alt="NBAGuessr Logo" />
          </header>
          <main className="App-content">
            <PlayerSearch />
            <GuessesDisplay />
            <div>
              <h2>PLAYER INFO</h2>
              <MetaDataTable />
            </div>
            <div>
              <h2>TEAM HISTORY</h2>
              <TeamInfo />
            </div>
            <div>
              <h2>PER GAME STATS</h2>
              <PerGameTable />
            </div>
          </main>
          {/* <footer><a href="https://www.flaticon.com/free-icons/basketball" title="basketball icons">Basketball icons created by Pixel perfect - Flaticon</a></footer> */}
        </div>
      </GameProvider>
    // </CookieProvider>
  );
}

export default App;
